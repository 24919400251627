<template>
  <div>
    正在处理中... 
    <el-button @click="close()">关闭</el-button>
  </div>
</template>

<script>
import { createAuth0Client } from '@auth0/auth0-spa-js'
import qs from 'qs'
import { authRequest } from '@/api'
// import '@/assets/css/abc.scss'
const redirectUri = 'https://mowan.pdd.is/auth'
export default {
  props: ['code', 'id', 'state', 'clientSecret', 'clientId', 'redirectUrl', 'price_url'],
  data() {
    return {
      auth0Client: null,
      error: null
    }
  },
  methods: {
    loginWithRedirect(o) {
      return this.auth0Client.loginWithRedirect(o)
    },
    close () {
     location.href = 'https://mowan.pdd.is/close_page'
    }
  },
  async created() {
    if (this.id) {
      sessionStorage.setItem('oauth-id', this.id)
    }
    if (this.clientId) {
      sessionStorage.setItem('oauth-clientId', this.clientId)
    }
    if (this.redirectUrl) {
      sessionStorage.setItem('oauth-redirectUrl', this.redirectUrl)
    }
    if (this.clientSecret) {
      sessionStorage.setItem('oauth-clientSecret', this.clientSecret)
    }
    if (this.price_url && this.code) {
        let reurl = decodeURIComponent(this.price_url) 
        if (reurl.includes('?')) {
          reurl = `${reurl}&code=${this.code}`
        } else {
          reurl = `${reurl}?code=${this.code}`
        }
        location.href = reurl
        return
    }
    let clientId = sessionStorage.getItem('oauth-clientId')
    let clientSecret = sessionStorage.getItem('oauth-clientSecret')
    if (!(clientId && clientSecret)) {
      this.$message({
        type: 'warning',
        message: 'clientId与clientSecret不能为空'
      })
      return 
    }
    let options = {
      domain: 'https://accounts.stockx.com',
      clientId: clientId, // 'FaZihfViJmpXpOrCxSjlvogCZeXMp5uV',
      clientSecret: clientSecret, // 'SLImRN9TZ2uDGFQ2iGTLHXkG-aeFGQfw21ja-7gnkN-c2pna5Cb8siyA_ApnF8FD',
      authorizationParams: {
        scope: 'offline_access openid',
        audience: 'gateway.stockx.com'
      }
    }
    let i = 0
    // 保存原始的fetch方法
    const originalFetch = window.fetch
    // 定义一个新的fetch方法
    window.fetch = async function (url, params) {
      // 检查URL是否是你想要拦截的
      if (url === 'https://accounts.stockx.com/oauth/token') {
        if (i++ != 0) {
          return
        }
        console.log('拦截', params, arguments)
        // 进行拦截操作，或者返回自定义的响应
        let json = qs.parse(params.body)
        if (!json.client_secret) {
          json.client_secret = options.clientSecret
        }
        json.id = sessionStorage.getItem('oauth-id')
        let redirectUrl = sessionStorage.getItem('oauth-redirectUrl')
       
        /**
         * 如果 id 参数是autoRedirect，并且有redirectUrl参数，就是自行处理 code
         * https://mowan.pdd.is/auth?id=autoRedirect&clientId=${clientId}&clientSecret=${clientSecret}&redirectUrl=${encodeURIComponent('http://xxxxx.com')}
         * 如上回调地址，获取 code 后，我会回调http://xxxxx.com?code=xx
         */
        if (json.id == 'autoRedirect' && redirectUrl) {
          let reurl = decodeURIComponent(redirectUrl) 
          if (reurl.includes('?')) {
            reurl = `${reurl}&code=${json.code}`
          } else {
            reurl = `${reurl}?code=${json.code}`
          }
          location.href = reurl
          return;
        }
        return authRequest(json).then((res) => {
          console.log(res)
          if (res.code === 20000) {
            location.href = 'https://mowan.pdd.is/close_page'
            return;
          }
          return {
            status: 200,
            statusText: 'OK',
            body: res.data
          }
        })
      } else {
        // 对于其他URL，调用原始的fetch方法
        return originalFetch.apply(this, [url, params])
      }
    }
    this.auth0Client = await createAuth0Client({
      ...options,
      authorizationParams: {
        redirect_uri: redirectUri,
        ...options.authorizationParams
      }
    })

    if (this.code) {
      console.log('code', this.code)
    } else {
      this.loginWithRedirect()
    }
    try {
      if (this.code && this.state) {
        await this.auth0Client.handleRedirectCallback()
      }
    } catch (e) {
      this.error = e
    }
  }
}
</script>

<style>
</style>